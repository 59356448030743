.form-gacha {
  .space-3-select-item-option-content {
    display: flex;
    align-items: center;
  }

  .select-token-form-item--token-logo {
    width: 34px;
    display: flex;
    justify-items: center;
    align-items: center;

    .space-3-image {
      display: flex;
      align-items: center;
    }
  }
}

@ant-prefix: space-3;